import React, { useState } from "react";
import wave from '../../images/Vector.png'
import linkedin from '../../images/socials/linkedin.png'
import twitter from '../../images/socials/twitter.png'
import github from '../../images/socials/github.png'
import { useTrail, a } from '@react-spring/web'
import { ScrollContainer, Animator, ScrollPage, Sticky, ZoomOut, Fade,  MoveOut, FadeOut } from "react-scroll-motion";

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
      config: { mass: 9, tension: 900, friction: 500 },
      opacity: open ? 1 : 0,
      x: open ? 0 : 0,
      height: open ? 75 : 0,
      from: { opacity: 0, x: 5, height: 0 },
    })
    return (
      <div>
        {trail.map(({ height, ...style }, index) => (
          <a.div key={index} style={style}>
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    )
  }

const Hero = () => {
    const [open, set] = useState(true)
    return (
        <>
        {/* <ScrollContainer>
            <ScrollPage > */}
            <div className="container text-white max-w-6xl mx-auto px-4 mt-10 pt-5 hero">
                <div className="grid grid-cols-7">
                    <div className="col-span-6">
                        <div className="grid grid-cols-1">
                            <div className="col-span-1">
                                <div className="grid grid-cols-8">
                                    <div className="col-span-3">
                                        <span className="text-[22px]" style={{float:'left',width:'50%'}}>Hello, welcome</span> 
                                        <span className="lg:ml-[-20px] mt-1 wow tada" data-wow-delay="1s" style={{float:'left',width:'50%'}}><img src={wave} alt="Hello"></img></span>
                                    </div>
                                    <div className="col-span-4"></div>
                                </div>
                            </div>
                            {/* <Animator animation={ZoomOut(1,3)}> */}
                                <div className="col-span-1 z-50 text-[30px] mb-20 md:text-[28px] leading-[30px] lg:text-[55px] lg:leading-[75px] mt-5">
                                <Trail open={open}>
                                    <p>Crafting Digital Dreams: Elevating Experiences through Code - Explore the World of Israel Akin-Akinsanya.</p>
                                </Trail>
                                </div>  
                            {/* </Animator> */}
                            <div className="col-span-1 mt-0 lg:mt-10 pt-10 text-[38px] leading-[50px] lg:text-[68px] lg:leading-[75px]">
                                <ul className="">
                                    <li className="inline-block pr-4 wow bounceInLeft" data-wow-duration="2s" data-wow-delay="1.5s"><a href="https://www.linkedin.com/in/israel-akin-akinsanya-773987186/"><img className="social" src={linkedin} alt="LinkedIn"/></a></li>
                                    <li className="inline-block pr-4 wow bounceInDown" data-wow-duration="2s" data-wow-delay="1.5s"><a href="https://twitter.com/_light_source_"><img className="social" src={twitter} alt="Twitter"/></a></li>
                                    <li className="inline-block wow bounceInRight" data-wow-duration="2s" data-wow-delay="1.2s"><a href="https://github.com/Lightsource-Pris"><img className="social" src={github} alt="GitHub"/></a></li>
                                </ul>
                            </div>
                            <div className="col-span-1 mt-6">
                                <a target="blank" href="mailto: israelakinakinsanya@gmail.com" className="py-[16px] px-[32px] font-medium text-black bg-white rounded-[24px] hover:bg-black hover:outline hover:outline-white hover:text-white transition duration-1000 w-[192px] text-center text-[16px]">
                                    Contact Me
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                    </div>
                </div>
            </div>
            {/* </ScrollPage>
            </ScrollContainer> */}
        </>
    )
}

export default Hero
